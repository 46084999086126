import { PricingTier } from '../components/landing/pricing';
import { Feature } from '../components/landing/features';

import PizzaIcon from '../../assets/svg/pizza.svg';
import GrowthIcon from '../../assets/svg/growth.svg';
import BusinessIcon from '../../assets/svg/business.svg';
import DiversityIcon from '../../assets/svg/diversity.svg';
import JobIcon from '../../assets/svg/job-description.svg';
import SchedulingIcon from '../../assets/svg/scheduling.svg';
import WorkflowIcon from '../../assets/svg/workflow.svg';


/**
 * Features
 */
export const features: Feature[] = [
    {
        label: 'Workflow designer',
        icon: '🛠️',
        header: 'Build your own application process',
        description: [
            'Creating your hiring process has never been easier.',
            'Marble\'s drag and drop interface allows you to easily organise the stages of your hiring process, and ' +
            'will eventually integrate seamlessly with third party tools.',
            'If this is your first time hiring, Marble\'s workflow wizard will determine where you\'re at, and ' +
            'suggest best practices towards hiring.'
        ],
        imagePath: WorkflowIcon,
        imageAlt: 'Workflow designer'
    },
    {
        label: 'Interview scheduler',
        icon: '🕒',
        header: 'Easily organise interviews around your schedule',
        description: [
            'Email chains back and forth trying to organise a meeting wastes time and is frustrating.',
            'Marble allows you to set your availability, and will automatically schedule interviews with applicants, ' +
            'by allowing them to choose which times suit you.'
        ],
        imagePath: SchedulingIcon,
        imageAlt: 'Interview scheduler'
    },
    {
        label: 'Job description builder',
        icon: '📝️',
        header: 'Build job descriptions for hundreds of roles',
        description: [
            'Choose from our pre-defined templates, and then customise those towards your own needs.',
            'Our job description generator encourages you to identify what candidates care about, without coming ' +
            'across as bland or generic'
        ],
        imagePath: JobIcon,
        imageAlt: 'Job description builder'
    },
    {
        label: 'Diversity and Inclusion first',
        icon: '🏳️‍🌈',
        header: 'Tools to reduce bias and hire the best',
        description: [
            'Increasing awareness of Diversity and Inclusion is built into the foundations of Marble.',
            'Any content Marble generated including job descriptions and offer letters have been designed to use ' +
            'inclusive language. Simple changes to your hiring process can have profound effects on how inclusive ' +
            'the entire process is.'
        ],
        imagePath: DiversityIcon,
        imageAlt: 'Diversity and Inclusion is built into Marble\'s foundations'
    },
]

/**
 * Pricing tiers
 */
export const tiers: PricingTier[] = [
    {
        price: "Free",
        icon: PizzaIcon,
        title: "Startup Basic",
        description: [
            "For small businesses who have one person in charge of their hiring.",
        ],
        features: [
            "Powerful workflow builder",
            "Applicant tracking",
            "Interview scheduling",
            "Job description builder",
            "Up to 5 concurrent postings"
        ],
        actionURL: "/start",
        disabled: false,
        buttonText: "Early access",
    },
    {
        price: "Billed per user/month",
        icon: GrowthIcon,
        title: "Startup Plus",
        description: [
            "For businesses who have multiple people involved in their hiring process.",
        ],
        features: [
            "All startup basic features",
            "Multiple account support",
            "Unlimited job postings",
            "Dynamic careers page",
            "Priority support",
            "Custom branding"
        ],
        actionURL: "/start",
        disabled: true,
        buttonText: "Releasing at launch"
    },
    {
        price: "Billed per user/month",
        icon: BusinessIcon,
        title: "Business",
        description: [
            "For businesses requiring flexibility in their hiring approach.",
        ],
        features: [
            "All startup plus features",
            "Dedicated support contact",
            "Native language support",
            "Employee Referrals",
            "SAML/SSO support"
        ],
        actionURL: "/start",
        disabled: true,
        buttonText: "Releasing at launch"
    }
];

