import * as React from "react";
import "./pricing.scss";

export interface PricingTiers {
    tiers: PricingTier[];
}

export interface PricingTier {
    price: string;
    icon: any;
    title: string;
    description: string[];
    features: string[];
    actionURL: string;
    buttonText: string;
    disabled: boolean;
}

const Pricing = (props: PricingTiers) => {
    const { tiers } = props;

    const renderedTiers = tiers.map((tier: PricingTier, index) => (
       <div className="tierWrapper" key={index}>
           <div className={"tier"}>
               <div className="section-title">
                   <h3>{tier.title}</h3>
               </div>
               <img className="tier-icon"
                    src={tier.icon}
                    alt={'Icon for ' + tier.title} />
               <h4 className="price">{tier.price}</h4>
               { tier.description.map((description: string, liIndex) => (
                   <p key={liIndex} className="description">{description}</p>
               ))}
               <ul className="feature-list">
                   { tier.features.map((feature: string, liIndex) => (
                       <li key={liIndex}>⭐ {feature}</li>
                   ))}
               </ul>
               <a aria-disabled={tier.disabled}
                  className={tier.disabled ? "disabled" : ""}
                  href={tier.disabled ? '' : '/start'}> {tier.buttonText}
               </a>
           </div>
       </div>
    ));

    return (
        <div className="pricing-component">
            { renderedTiers }
        </div>
    );
}


export default Pricing;