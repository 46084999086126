import * as React from "react";
import "../styles/landing.scss";
import { tiers, features } from '../config/config';

import LandingImage from "../../assets/svg/resume.svg"
import Blob from "../../assets/svg/blob.svg";

import Header from "../components/shared/header";
import Pricing from '../components/landing/pricing';
import Footer from '../components/shared/footer';
import Features from '../components/landing/features';
import { MarbleHelmet } from '../components/shared/helmet';
import CookieConsent from 'react-cookie-consent';

const IndexPage = () => {
    return (
        <>
            <MarbleHelmet
                pageName="Marble | Home"
                ogImage="https://marble-landing-site-assets.s3.eu-west-2.amazonaws.com/resume.png"
                ogTitle="Marble: The free hiring platform built for startups"
                ogURL="https://trymarble.co/"
            />
            <Header />

            <main>
                <section id="call-to-action">
                    <img className="landing-image" src={LandingImage} alt="Illustration of an interview"/>
                    <h1 id="callout">Hiring made simple. <span className="highlight">Free for startups</span></h1>
                    <p>Marble manages your hiring process, so you can focus on what matters</p>
                </section>

                <section id="features">
                    <Features features={features} />
                </section>

                <section id="pricing"
                         style={{backgroundImage: "url(" + Blob + ")"}}>
                    <h2>Plans to match your size</h2>
                    <Pricing tiers={tiers}/>
                </section>
            </main>

            <Footer />

            <CookieConsent
                enableDeclineButton
                declineButtonText="Decline"
                location="bottom"
                buttonText="I understand"
                cookieName="gatsby-gdpr-google-analytics">
                We use cookies to better understand who is visiting our site.
            </CookieConsent>
        </>
    )
}

export default IndexPage
