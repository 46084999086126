import * as React from 'react';
import "./features.scss";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export interface FeatureCarouselProps {
    features: Feature[];
}

export interface Feature {
    label: string;
    icon: string;
    header: string;
    description: string[];
    imagePath: string;
    imageAlt: string
}

const Features = (props: FeatureCarouselProps) => {
    const { features } = props;

    const tabListItems = features.map((feature:Feature, index) => (
        <Tab key={index}>{feature.icon} {feature.label}</Tab>
    ));

    const tabPanelItems = features.map((feature: Feature, index) => {
        const description = feature.description.map((paragraph, pIndex) => (
            <p key={pIndex}>{paragraph}</p>
        ));

        return (
            <TabPanel key={index} className="feature-content">
                <section className="description">
                    <h2>{feature.header}</h2>
                    {description}
                </section>
                <section className="figure">
                    <figure>
                        <img alt='' src={feature.imagePath}/>
                        <figcaption>{feature.imageAlt}</figcaption>
                    </figure>
                </section>
            </TabPanel>
        );
    });

    return (
        <Tabs>
            <TabList>
                {tabListItems}
            </TabList>
            {tabPanelItems}
        </Tabs>
    )
}

export default Features;